import React from 'react'

const SvgMoiveAdvantage3 = props => (
  <svg id='moive_advantage3_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.moive_advantage3_svg__st1{fill:#334966}.moive_advantage3_svg__st2{fill:#fd5c1f}'}</style>
    <g id='moive_advantage3_svg__\u7BA1\u7406\u7B80\u5355-48x48'>
      <circle id='moive_advantage3_svg__Oval-7' cx={25} cy={25} r={23} fill='none' stroke='#334966' strokeWidth={2} />
      <path id='moive_advantage3_svg__Rectangle-53-Copy' className='moive_advantage3_svg__st1' d='M14 16h2v18h-2z' />
      <path id='moive_advantage3_svg__Rectangle-53-Copy-2' className='moive_advantage3_svg__st1' d='M34 16h2v18h-2z' />
      <circle id='moive_advantage3_svg__Oval-20' className='moive_advantage3_svg__st2' cx={15} cy={16} r={3} />
      <circle id='moive_advantage3_svg__Oval-20-Copy-2' className='moive_advantage3_svg__st2' cx={35} cy={16} r={3} />
      <circle id='moive_advantage3_svg__Oval-20-Copy' className='moive_advantage3_svg__st2' cx={15} cy={34} r={3} />
      <circle id='moive_advantage3_svg__Oval-20-Copy-3' className='moive_advantage3_svg__st2' cx={35} cy={34} r={3} />
      <circle id='moive_advantage3_svg__Oval-20-Copy-4' className='moive_advantage3_svg__st2' cx={25} cy={25} r={4} />
      <path id='moive_advantage3_svg__Rectangle-53' className='moive_advantage3_svg__st1' d='M24 13h2v24h-2z' />
    </g>
  </svg>
)

export default SvgMoiveAdvantage3
