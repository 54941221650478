import React from 'react'

const SvgMoiveSolution = props => (
  <svg
    id='moive_solution_svg__\u89E3\u51B3\u65B9\u6848'
    x={0}
    y={0}
    viewBox='0 0 1001 397'
    xmlSpace='preserve'
    {...props}
  >
    <style>
      {
        '.moive_solution_svg__st2{font-family:&apos;SourceHanSansCN-Regular-GBpc-EUC-H&apos;}.moive_solution_svg__st4{fill:#7b899c}.moive_solution_svg__st6{fill-rule:evenodd;clip-rule:evenodd}.moive_solution_svg__st6,.moive_solution_svg__st7{fill:#fd5c1f}.moive_solution_svg__st8{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}.moive_solution_svg__st9{font-size:14px}.moive_solution_svg__st11{fill:#fff;stroke:#7b899c;stroke-dasharray:4,2}.moive_solution_svg__st12,.moive_solution_svg__st13{fill:#fff;stroke:#fd5c1f;stroke-width:2}.moive_solution_svg__st13{fill:none}.moive_solution_svg__st14{fill:#e95219}.moive_solution_svg__st15{fill:#252a3a}'
      }
    </style>
    <path
      id='moive_solution_svg__\u5706\u89D2\u77E9\u5F62_3_\u62F7\u8D1D_3'
      d='M240.5.49h490c2.76 0 5 2.24 5 5v140c0 2.76-2.24 5-5 5h-490c-2.76 0-5-2.24-5-5v-140c0-2.76 2.24-5 5-5z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='#fff'
      stroke='#fd5c1f'
      strokeDasharray='4,2'
    />
    <text
      id='moive_solution_svg__\u5F71\u89C6\u5236\u4F5C\u65B9'
      transform='translate(444.488 28)'
      className='moive_solution_svg__st2'
      fontSize={16.003}
      fill='#182940'
    >
      {'\u5F71\u89C6\u5236\u4F5C\u65B9'}
    </text>
    <text
      id='moive_solution_svg__\u6D41\u7A0B\u7BA1\u7406'
      transform='matrix(1.0111 0 0 1 290.129 129.026)'
      className='moive_solution_svg__st4 moive_solution_svg__st2'
      fontSize={14.004}
    >
      {'\u6D41\u7A0B\u7BA1\u7406'}
    </text>
    <path
      id='moive_solution_svg__\u77E9\u5F62_81'
      className='moive_solution_svg__st6'
      d='M295.16 44.5h50.34c2.76 0 5 2.24 5 5v49.68c0 2.76-2.24 5-5 5h-50.34a4.985 4.985 0 01-5-4.98V49.5c0-2.76 2.24-5 5-5z'
    />
    <path
      id='moive_solution_svg__\u77E9\u5F62_81_\u62F7\u8D1D'
      className='moive_solution_svg__st7'
      d='M460.5 44.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='moive_solution_svg__\u77E9\u5F62_81_\u62F7\u8D1D_2'
      className='moive_solution_svg__st6'
      d='M635.02 44.5h50.16c2.76 0 5 2.24 5 5v50.15c0 2.76-2.24 5-5 5h-50.16c-2.76 0-5-2.24-5-5V49.5c0-2.76 2.24-5 5-5z'
    />
    <path
      id='moive_solution_svg__\u5F62\u72B6_4'
      className='moive_solution_svg__st8'
      d='M499.11 63.82l2.16-1.39-.8-2-2.49.58c-.36-.5-.79-.95-1.28-1.32l.56-2.53-1.96-.83-1.34 2.19c-.61-.1-1.22-.1-1.83-.01l-1.33-2.19-1.97.81.54 2.52c-.5.37-.93.82-1.3 1.32l-2.46-.6-.82 2 2.13 1.39c-.1.62-.1 1.25-.01 1.87l-2.15 1.35.8 2 2.48-.55c.37.5.8.95 1.3 1.32l-.59 2.51 1.96.83 1.38-2.18c.61.09 1.22.09 1.83 0l1.36 2.2 1.96-.82-.57-2.53c.49-.37.92-.8 1.28-1.3l2.5.57.82-1.99-2.17-1.38c.09-.61.09-1.23.01-1.84zm-3.36 2.06a2.952 2.952 0 01-3.83 1.64c-.01-.01-.03-.01-.04-.02a3.038 3.038 0 01-1.59-3.94c.6-1.51 2.31-2.24 3.82-1.64.02.01.03.01.05.02 1.51.66 2.22 2.41 1.59 3.94zm4.59 18.67l1.82-1.16-.68-1.67-2.07.49a4.9 4.9 0 00-1.08-1.1l.48-2.12-1.64-.69-1.12 1.83c-.51-.09-1.02-.09-1.53-.01l-1.11-1.83-1.64.68.45 2.1c-.42.31-.79.68-1.09 1.1l-2.05-.5-.69 1.67 1.78 1.16c-.08.52-.08 1.04-.01 1.56l-1.79 1.13.67 1.67 2.07-.46c.3.42.67.79 1.08 1.1l-.49 2.1 1.64.69 1.15-1.82c.5.08 1.02.08 1.52 0l1.14 1.84 1.64-.69-.48-2.11c.41-.31.77-.67 1.07-1.09l2.09.48.68-1.66-1.81-1.15c.08-.51.08-1.03 0-1.54zm-2.79 1.72a2.47 2.47 0 01-3.2 1.37c-.01-.01-.02-.01-.04-.02a2.543 2.543 0 01-1.33-3.29 2.475 2.475 0 013.22-1.36c.01 0 .02.01.02.01 1.26.55 1.85 2.01 1.33 3.29zm-7.18-6.29v-2.82l-3.3-.55a8.18 8.18 0 00-.91-2.23l1.93-2.79-1.97-1.99-2.7 1.98a7.42 7.42 0 00-2.21-.93l-.57-3.35h-2.78l-.53 3.34c-.78.19-1.53.5-2.21.92l-2.7-1.96-1.97 1.99 1.9 2.76c-.41.7-.72 1.47-.92 2.26l-3.26.55v2.82l3.26.59c.2.79.51 1.55.92 2.25l-1.93 2.74 1.97 2 2.72-1.94c.68.42 1.43.73 2.21.93l.54 3.33h2.78l.58-3.34c.78-.2 1.52-.51 2.2-.94l2.74 1.96 1.96-2-1.95-2.76c.41-.69.71-1.45.9-2.23l3.3-.59zm-11.1 2.55c-2.17.04-3.96-1.7-4-3.87-.04-2.17 1.7-3.96 3.87-4s3.96 1.7 4 3.87v.06a3.9 3.9 0 01-3.87 3.94z'
    />
    <text
      id='moive_solution_svg__\u9AD8\u901F\u4F20\u8F93\u5F15\u64CE'
      transform='translate(444.496 129.501)'
      className='moive_solution_svg__st4 moive_solution_svg__st2 moive_solution_svg__st9'
    >
      {'\u9AD8\u901F\u4F20\u8F93\u5F15\u64CE'}
    </text>
    <text
      id='moive_solution_svg__\u5B58\u50A8\u8BBE\u5907'
      transform='translate(635.015 129.727)'
      className='moive_solution_svg__st4 moive_solution_svg__st2'
      fontSize={13.965}
    >
      {'\u5B58\u50A8\u8BBE\u5907'}
    </text>
    <path
      id='moive_solution_svg__\u5F62\u72B6_7'
      className='moive_solution_svg__st8'
      d='M675.3 85.85V63.3h-5.51v-1.88h7.35v26.31h-7.35v-1.88h5.51zm-29.41 0V63.3h5.51v-1.88h-7.35v26.31h7.35v-1.88h-5.51zm7.35-26.31h14.71v30.07h-14.71V59.54zm1.84 1.88v26.31h11.03V61.42h-11.03zm3.68 3.76h3.67v1.88h-3.67v-1.88zm0 7.52h3.67v1.87h-3.67V72.7zm0 7.51h3.67v1.88h-3.67v-1.88z'
    />
    <path
      id='moive_solution_svg__\u5706\u89D2\u77E9\u5F62_3'
      className='moive_solution_svg__st11'
      d='M5.5 250.5h190c2.76 0 5 2.24 5 5v136c0 2.76-2.24 5-5 5H5.5c-2.76 0-5-2.24-5-5v-136c0-2.76 2.24-5 5-5z'
    />
    <path
      id='moive_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D'
      className='moive_solution_svg__st7'
      d='M119.5 292.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='moive_solution_svg__\u5F62\u72B6_9'
      className='moive_solution_svg__st8'
      d='M159.32 321.3l-6.3 3.58c.01-1.65.42-4.24-1.18-5.27-1.29-.83-3.09-.54-4.57-.54h-.89a3.49 3.49 0 002.43-2.93 1.733 1.733 0 000-.63 3.277 3.277 0 00-3.55-2.99 3.285 3.285 0 00-3 3.31 3.258 3.258 0 002.71 3.24h-6.01a6.336 6.336 0 003.35-5.11c.03-.17.03-.35 0-.52a5.934 5.934 0 00-5.78-5.6c-3.26-.17-5.64 2.8-5.79 5.85a5.577 5.577 0 003.66 5.37h-2.61c-1.99 0-3.68.51-3.95 2.85-.11 1.94-.11 3.87 0 5.81v5.07c-.12.95-.09 1.92.08 2.86.92 2.6 3.45 2.17 5.66 2.17h16.37c.18.01.37 0 .55-.05 2.32-.43 2.52-2.38 2.52-4.45v-.85l6.3 3.57a.955.955 0 001.42-.82v-13.09c0-.53-.43-.95-.96-.95a.93.93 0 00-.46.12zm-15.18-5.48c-.1-1.83 2.71-1.83 2.81 0 0 .78-.63 1.41-1.4 1.41-.78 0-1.41-.63-1.41-1.41zm-11.52-2.13c-.25-5.09 7.57-5.07 7.83 0a3.919 3.919 0 01-4 3.83 3.924 3.924 0 01-3.83-3.83zm26.24 19.9c-1.79-1.01-3.58-2.03-5.36-3.04v-3.76c1.78-1.01 3.57-2.03 5.36-3.04v9.84zm-8.67 2.32h-19.13c-1.9 0-1.33-2.83-1.33-4.01v-9.99c0-1.16 1.45-.94 2.06-.94h18.39c1.45 0 .95 3.33.95 4.01v6.6c0 .83.56 4.15-.94 4.33z'
    />
    <path
      id='moive_solution_svg__\u77E9\u5F62_82'
      className='moive_solution_svg__st7'
      d='M29.5 292.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <text
      id='moive_solution_svg__\u5B9E\u62CD\u6444\u5F71\u7EC4'
      transform='translate(65.499 379.5)'
      className='moive_solution_svg__st4 moive_solution_svg__st2 moive_solution_svg__st9'
    >
      {'\u5B9E\u62CD\u6444\u5F71\u7EC4'}
    </text>
    <circle id='moive_solution_svg__\u692D\u5706_4' className='moive_solution_svg__st12' cx={100.5} cy={244.5} r={40} />
    <path
      id='moive_solution_svg__\u5706\u89D2\u77E9\u5F62_3_\u62F7\u8D1D'
      className='moive_solution_svg__st11'
      d='M235.5 250.5h190c2.76 0 5 2.24 5 5v136c0 2.76-2.24 5-5 5h-190c-2.76 0-5-2.24-5-5v-136c0-2.76 2.24-5 5-5z'
    />
    <path
      id='moive_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D_2'
      className='moive_solution_svg__st7'
      d='M349.5 292.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='moive_solution_svg__\u5F62\u72B6_10'
      className='moive_solution_svg__st8'
      d='M375.5 304.5c-9.94 0-18 8.06-18 18s8.06 18 18 18 18-8.06 18-18-8.06-18-18-18zm-9.25 20.11c-.06.71-.68 1.24-1.39 1.18h-.01c-.68.04-1.27-.47-1.32-1.16v-4.11c0-.73.59-1.32 1.32-1.32.74 0 1.36.58 1.4 1.32v4.09zm5.32 3.16c0 .73-.58 1.32-1.31 1.32h-.09c-.69.04-1.28-.49-1.32-1.17v-10.69c0-.73.59-1.32 1.32-1.32.74 0 1.36.58 1.4 1.32v10.54zm5.27 2.64c0 .73-.59 1.32-1.32 1.32-.73 0-1.32-.59-1.32-1.32v-15.82c0-.73.59-1.32 1.32-1.32.73 0 1.32.59 1.32 1.32v15.82zm5.35-2.64c.01.73-.58 1.32-1.3 1.33-.73.01-1.32-.58-1.33-1.3V317.24c-.01-.73.58-1.32 1.3-1.33.73-.01 1.32.58 1.33 1.3V327.77zm5.28-3.16c-.02.67-.57 1.2-1.24 1.18h-.08c-.68.04-1.27-.47-1.32-1.16v-4.11c0-.73.59-1.32 1.32-1.32.73 0 1.32.59 1.32 1.32v4.09z'
    />
    <path
      id='moive_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D_2-2'
      className='moive_solution_svg__st7'
      d='M259.5 292.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='moive_solution_svg__\u5F62\u72B6_11'
      className='moive_solution_svg__st8'
      d='M299.22 329.04h-3.27v-1.64h-1.64v11.46h-19.63V327.4h-1.64v1.64h-3.27v-1.64h-1.64v10.71a2.38 2.38 0 002.38 2.38h27.98c1.31 0 2.37-1.06 2.37-2.37V327.4h-1.64v1.64zm0 1.63v3.28h-3.27v-3.28h3.27zm-26.18 0v3.28h-3.27v-3.28h3.27zm-3.27 7.44v-2.53h3.27v3.28h-2.53a.753.753 0 01-.74-.75zm28.72.75h-2.54v-3.28h3.27v2.53c.01.41-.32.74-.73.75zm-28.72-18h3.27v1.64h1.64v-1.64h19.63v1.64h1.64v-1.64h3.27v1.64h1.64v-15.62c0-1.31-1.06-2.38-2.37-2.38h-27.98c-1.31 0-2.38 1.07-2.38 2.38v15.62h1.64v-1.64zm0-1.64v-3.27h3.27v3.27h-3.27zm3.27-4.91h-3.27v-3.27h3.27v3.27zm22.91-3.27h3.27v3.27h-3.27v-3.27zm0 8.18v-3.27h3.27v3.27h-3.27zm2.54-13.09c.41.01.73.34.73.75v2.53h-3.27v-3.28h2.54zm-4.18 0v13.09h-19.63v-13.09h19.63zm-23.8 0h2.53v3.28h-3.27v-2.53c0-.41.33-.75.74-.75zm-4.01 18h36v1.64h-36v-1.64z'
    />
    <text
      id='moive_solution_svg__\u540E\u671F\u526A\u8F91\u914D\u97F3'
      transform='translate(288.505 379.5)'
      className='moive_solution_svg__st4 moive_solution_svg__st2 moive_solution_svg__st9'
    >
      {'\u540E\u671F\u526A\u8F91\u914D\u97F3'}
    </text>
    <circle
      id='moive_solution_svg__\u692D\u5706_4_\u62F7\u8D1D'
      className='moive_solution_svg__st12'
      cx={330.5}
      cy={244.5}
      r={40}
    />
    <path
      id='moive_solution_svg__\u5706\u89D2\u77E9\u5F62_3_\u62F7\u8D1D_2'
      className='moive_solution_svg__st11'
      d='M490.5 250.5h230c2.76 0 5 2.24 5 5v136c0 2.76-2.24 5-5 5h-230c-2.76 0-5-2.24-5-5v-136c0-2.76 2.24-5 5-5z'
    />
    <path
      id='moive_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D_3'
      className='moive_solution_svg__st7'
      d='M624.5 292.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='moive_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D_3-2'
      className='moive_solution_svg__st7'
      d='M534.5 292.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <text
      id='moive_solution_svg__\u5206\u5305\u534F\u4F5C_\u6E32\u67D3\u7279\u6548'
      transform='translate(542.495 379.5)'
      className='moive_solution_svg__st4 moive_solution_svg__st2 moive_solution_svg__st9'
    >
      {'\u5206\u5305\u534F\u4F5C\uFF1A\u6E32\u67D3\u7279\u6548'}
    </text>
    <circle
      id='moive_solution_svg__\u692D\u5706_4_\u62F7\u8D1D_2'
      className='moive_solution_svg__st12'
      cx={605.5}
      cy={244.5}
      r={40}
    />
    <path
      id='moive_solution_svg__\u5706\u89D2\u77E9\u5F62_3_\u62F7\u8D1D_4'
      className='moive_solution_svg__st11'
      d='M805.5 250.5h190c2.76 0 5 2.24 5 5v136c0 2.76-2.24 5-5 5h-190c-2.76 0-5-2.24-5-5v-136c0-2.76 2.24-5 5-5z'
    />
    <path
      id='moive_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D_4'
      className='moive_solution_svg__st7'
      d='M922.5 292.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='moive_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D_4-2'
      className='moive_solution_svg__st7'
      d='M826.5 292.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      className='moive_solution_svg__st8'
      d='M868.47 337.93c-.44.01-.86-.15-1.18-.46l-9.11-9.36a1.643 1.643 0 010-2.32c.29-.31.7-.49 1.13-.49.43 0 .83.18 1.13.49l9.11 9.36c.31.32.48.74.49 1.18.06.82-.56 1.54-1.38 1.6h-.19z'
    />
    <path
      className='moive_solution_svg__st8'
      d='M864.57 320.03l-5.82-5.85 1.43-8.33c.1-.43-.08-.87-.44-1.12-.35-.26-.82-.31-1.21-.11l-7.22 3.94-7.23-3.94c-.39-.19-.86-.15-1.21.11-.36.25-.54.69-.44 1.12l1.43 8.33-5.93 5.85c-.3.29-.43.71-.33 1.12.11.42.45.73.88.79l8.11 1.24 3.62 7.54c.2.38.57.63.99.67.43 0 .82-.27.98-.67l3.61-7.54 8.23-1.24c.43-.06.77-.37.88-.79.13-.4 0-.85-.33-1.12zm-9.93.35c-.36 0-.7.2-.88.51l-2.55 5.44-2.55-5.44a.75.75 0 00-.78-.51l-5.81-.91 4.23-4.23c.2-.26.3-.58.29-.91l-.98-5.95 5.21 2.82c.26.2.62.2.88 0l5.2-2.82-.97 5.95c-.06.33.05.67.29.91l4.23 4.23-5.81.91zM841.12 329.09l-1.54 4.61-4.62 1.58 4.62 1.58 1.54 4.73 1.54-4.73 4.48-1.58-4.48-1.58zM854.49 332.47l-.65 2.14-2.08.79 2.08.67.65 2.14.77-2.14 2.08-.67-2.08-.79zM575.47 338.39c-.44.01-.86-.15-1.18-.46l-9.11-9.36a1.643 1.643 0 010-2.32c.29-.31.7-.49 1.13-.49.43 0 .83.18 1.13.49l9.11 9.36c.31.32.48.74.49 1.18.06.82-.56 1.54-1.38 1.6h-.19z'
    />
    <path
      className='moive_solution_svg__st8'
      d='M571.57 320.49l-5.82-5.85 1.43-8.33c.1-.43-.08-.87-.44-1.12-.35-.26-.82-.31-1.21-.11l-7.22 3.94-7.23-3.94c-.39-.19-.86-.15-1.21.11-.36.25-.54.69-.44 1.12l1.43 8.33-5.93 5.85c-.3.29-.43.71-.33 1.12.11.42.45.73.88.79l8.11 1.24 3.62 7.54c.2.38.57.63.99.67.43 0 .82-.27.98-.67l3.61-7.54 8.23-1.24c.43-.06.77-.37.88-.79.13-.41 0-.85-.33-1.12zm-9.93.34c-.36 0-.7.2-.88.51l-2.55 5.44-2.55-5.44a.75.75 0 00-.78-.51l-5.81-.91 4.23-4.23c.2-.26.3-.58.29-.91l-.98-5.95 5.21 2.82c.26.2.62.2.88 0l5.2-2.82-.97 5.95c-.06.33.05.67.29.91l4.23 4.23-5.81.91zM548.12 329.55l-1.54 4.61-4.62 1.58 4.62 1.58 1.54 4.73 1.54-4.73 4.48-1.58-4.48-1.58zM561.49 332.93l-.65 2.14-2.08.79 2.08.67.65 2.14.77-2.14 2.08-.67-2.08-.79z'
    />
    <text
      id='moive_solution_svg__CG_\u5236\u4F5C\u56E2\u961F'
      transform='translate(861.495 379.5)'
      className='moive_solution_svg__st4 moive_solution_svg__st2 moive_solution_svg__st9'
    >
      {'CG \u5236\u4F5C\u56E2\u961F'}
    </text>
    <circle
      id='moive_solution_svg__\u692D\u5706_4_\u62F7\u8D1D_3'
      className='moive_solution_svg__st12'
      cx={900.5}
      cy={244.5}
      r={40}
    />
    <text
      id='moive_solution_svg__\u56DE\u4F20\u7D20\u6750'
      transform='translate(125.498 82.501)'
      className='moive_solution_svg__st4 moive_solution_svg__st2 moive_solution_svg__st9'
    >
      {'\u56DE\u4F20\u7D20\u6750'}
    </text>
    <path id='moive_solution_svg__\u5F62\u72B6_5' className='moive_solution_svg__st13' d='M96.5 203.5v-109h122' />
    <path
      id='moive_solution_svg__\u591A\u8FB9\u5F62_2'
      className='moive_solution_svg__st6'
      d='M229.65 95.56l-11.99 8.01V87.55l11.99 8.01z'
    />
    <path
      id='moive_solution_svg__\u5F62\u72B6_5_\u62F7\u8D1D'
      className='moive_solution_svg__st13'
      d='M897.5 203.5V94.44H745.66'
    />
    <path
      id='moive_solution_svg__\u591A\u8FB9\u5F62_2_\u62F7\u8D1D'
      className='moive_solution_svg__st6'
      d='M734.5 93.5l11.99 8.02V85.49L734.5 93.5z'
    />
    <path
      id='moive_solution_svg__\u591A\u8FB9\u5F62_2_\u62F7\u8D1D_3'
      className='moive_solution_svg__st6'
      d='M329.5 168.5h2v20h-2v-20zm.98 29l-8-9.99h16l-8 9.99zm0-39l-8 9.99h16l-8-9.99z'
    />
    <path
      id='moive_solution_svg__\u591A\u8FB9\u5F62_2_\u62F7\u8D1D_4'
      className='moive_solution_svg__st6'
      d='M743.5 321.53h40v2h-40v-2zm-9 .98l9.99-8v16l-9.99-8zm59 0l-9.99-8v16l9.99-8z'
    />
    <path
      className='moive_solution_svg__st14'
      d='M103.88 225.73c-4.19 0-8.36.81-12.05 1.71l2.72 1.96c-3.67.69-7.43.48-9.49.03v8.03c2.8.74 11.99.2 16.03-1.45l-1.26-1.53c1.49-.15 2.49-.18 4.05-.18 4.29 0 8.37.6 12.05 1.67v-8.52c-3.48-.87-7.86-1.72-12.05-1.72z'
    />
    <path
      className='moive_solution_svg__st15'
      d='M116.67 256.85c-2.81.29-4.78.75-6.77 1.33v2.36c-3.1.7-8.14 1.39-12.19 1.14-4.18-.26-9.58-1.78-11.91-2.66v-8.48c3.3 1.75 9.55 2.94 14.7 2.94 2.87 0 6.39-.38 8.46-.81l-.41-2.12c2.64-.9 4.28-1.67 8.11-2.05v8.35zM111.4 237.58c-2.93 0-7.21.67-8.41 1.01l1.4 2.24c-3.15.79-6.29.78-8.6.78-5.41 0-10.01-1.14-14.79-2.96v8.5c3.67 1.25 8.39 2.22 12.86 2.22s9.37-.61 13.56-2.06l-.39-1.03c1.46-.18 2.7-.28 4.3-.28 3 0 5.53.11 8.67.85v-8.52c-1.73-.37-5.67-.75-8.6-.75z'
    />
    <g>
      <path
        className='moive_solution_svg__st14'
        d='M333.28 227c-4.19 0-8.36.81-12.05 1.71l2.72 1.96c-3.67.69-7.43.48-9.49.03v8.03c2.8.74 11.99.2 16.03-1.45l-1.26-1.53c1.49-.15 2.49-.18 4.05-.18 4.29 0 8.37.6 12.05 1.67v-8.52c-3.48-.87-7.86-1.72-12.05-1.72z'
      />
      <path
        className='moive_solution_svg__st15'
        d='M346.07 258.12c-2.81.29-4.78.75-6.77 1.33v2.36c-3.1.7-8.14 1.39-12.19 1.14-4.18-.26-9.58-1.78-11.91-2.66v-8.48c3.3 1.75 9.55 2.94 14.7 2.94 2.87 0 6.39-.38 8.46-.81l-.41-2.12c2.64-.9 4.28-1.67 8.11-2.05v8.35zM340.8 238.85c-2.93 0-7.21.67-8.41 1.01l1.4 2.24c-3.15.79-6.29.78-8.6.78-5.41 0-10.01-1.14-14.79-2.96v8.5c3.67 1.25 8.39 2.22 12.86 2.22 4.47 0 9.37-.61 13.56-2.06l-.39-1.03c1.46-.18 2.7-.28 4.3-.28 3 0 5.53.11 8.67.85v-8.52c-1.73-.37-5.67-.75-8.6-.75z'
      />
    </g>
    <g>
      <path
        className='moive_solution_svg__st14'
        d='M608.33 225.48c-4.19 0-8.36.81-12.05 1.71l2.72 1.96c-3.67.69-7.43.48-9.49.03v8.03c2.8.74 11.99.2 16.03-1.45l-1.26-1.53c1.49-.15 2.49-.18 4.05-.18 4.29 0 8.37.6 12.05 1.67v-8.52c-3.48-.87-7.86-1.72-12.05-1.72z'
      />
      <path
        className='moive_solution_svg__st15'
        d='M621.12 256.6c-2.81.29-4.78.75-6.77 1.33v2.36c-3.1.7-8.14 1.39-12.19 1.14-4.18-.26-9.58-1.78-11.91-2.66v-8.48c3.3 1.75 9.55 2.94 14.7 2.94 2.87 0 6.39-.38 8.46-.81l-.41-2.12c2.64-.9 4.28-1.67 8.11-2.05v8.35zM615.85 237.33c-2.93 0-7.21.67-8.41 1.01l1.4 2.24c-3.15.79-6.29.78-8.6.78-5.41 0-10.01-1.14-14.79-2.96v8.5c3.67 1.25 8.39 2.22 12.86 2.22s9.37-.61 13.56-2.06l-.39-1.03c1.46-.18 2.7-.28 4.3-.28 3 0 5.53.11 8.67.85v-8.52c-1.74-.36-5.67-.75-8.6-.75z'
      />
    </g>
    <g>
      <path
        className='moive_solution_svg__st14'
        d='M903.88 226.5c-4.19 0-8.36.81-12.05 1.71l2.72 1.96c-3.67.69-7.43.48-9.49.03v8.03c2.8.74 11.99.2 16.03-1.45l-1.26-1.53c1.49-.15 2.49-.18 4.05-.18 4.29 0 8.37.6 12.05 1.67v-8.52c-3.48-.87-7.86-1.72-12.05-1.72z'
      />
      <path
        className='moive_solution_svg__st15'
        d='M916.67 257.62c-2.81.29-4.78.75-6.77 1.33v2.36c-3.1.7-8.14 1.39-12.19 1.14-4.18-.26-9.58-1.78-11.91-2.66v-8.48c3.3 1.75 9.55 2.94 14.7 2.94 2.87 0 6.39-.38 8.46-.81l-.41-2.12c2.64-.9 4.28-1.67 8.11-2.05v8.35zM911.4 238.35c-2.93 0-7.21.67-8.41 1.01l1.4 2.24c-3.15.79-6.29.78-8.6.78-5.41 0-10.01-1.14-14.79-2.96v8.5c3.67 1.25 8.39 2.22 12.86 2.22s9.37-.61 13.56-2.06l-.39-1.03c1.46-.18 2.7-.28 4.3-.28 3 0 5.53.11 8.67.85v-8.52c-1.73-.37-5.67-.75-8.6-.75z'
      />
    </g>
    <g>
      <path
        className='moive_solution_svg__st8'
        d='M339.49 56.61H302.8c-1.9 0-3.44 1.55-3.44 3.45v25.31c0 1.9 1.54 3.45 3.44 3.46h36.69c1.9-.01 3.44-1.56 3.44-3.46V60.06v-.01c0-1.9-1.54-3.44-3.44-3.44zm1.15 28.76c0 .64-.51 1.15-1.15 1.15H302.8c-.64 0-1.15-.51-1.15-1.15V60.06c0-.64.51-1.15 1.15-1.15h36.69c.64 0 1.15.51 1.15 1.15v25.31zM308.21 91.75c-.04 0-.07.03-.07.07v2.47c0 .04.03.07.07.07h25.86c.04 0 .07-.03.07-.07v-2.47c0-.04-.03-.07-.07-.07h-25.86z'
      />
      <path
        className='moive_solution_svg__st8'
        d='M331.67 74.78c-.57 0-1.12.12-1.63.32l-5.01-7.94c.44-.71.71-1.54.71-2.44v-.02a4.605 4.605 0 00-4.63-4.59c-2.55.01-4.6 2.08-4.59 4.63 0 1.09.4 2.08 1.04 2.87l-4.72 7.48c-.52-.2-1.08-.32-1.67-.32-2.55.01-4.6 2.08-4.59 4.63s2.08 4.6 4.63 4.59a4.6 4.6 0 004.43-3.46h11.59c.52 1.99 2.32 3.47 4.47 3.46a4.607 4.607 0 004.59-4.61v-.02c0-2.53-2.07-4.59-4.62-4.58zm-16.95 1.67l4.69-7.45a4.451 4.451 0 003.91-.24l4.84 7.68c-.43.52-.75 1.12-.93 1.8h-11.6c-.15-.67-.48-1.27-.91-1.79zm6.44-14.02a2.3 2.3 0 110 4.6 2.3 2.3 0 010-4.6zm-9.94 19.26a2.3 2.3 0 010-4.6 2.3 2.3 0 010 4.6zm20.49 0a2.3 2.3 0 010-4.6 2.3 2.3 0 010 4.6z'
      />
    </g>
    <g>
      <path
        className='moive_solution_svg__st8'
        d='M63.03 317.17v-.13c-.07-4.79-4.01-8.61-8.8-8.54-4.79.07-8.61 4.01-8.54 8.8.05 3.25 1.88 6.04 4.54 7.49-5.06 1.86-8.65 6.72-8.62 12.42 0 .09 0 .18.01.27l.01.11c.01.5.42.91.92.91h.03c.51-.02.91-.46.89-.97 0-.06 0-.12-.01-.18v-.14c-.04-6.23 4.97-11.31 11.2-11.37.02 0 .05-.01.07-.01 4.64-.17 8.33-3.99 8.3-8.66zm-15.44-.1a6.801 6.801 0 016.9-6.7c3.73.03 6.73 3.07 6.7 6.8v.1c-.06 3.76-3.14 6.75-6.9 6.7s-6.75-3.14-6.7-6.9z'
      />
      <path
        className='moive_solution_svg__st8'
        d='M45.9 325.61c0-.52-.42-.94-.94-.94-.09 0-.18.01-.27.02-.03 0-.05-.02-.08-.02a6.106 6.106 0 01-5.85-5.85 6.089 6.089 0 015.85-6.34.935.935 0 000-1.87 7.972 7.972 0 00-3.79 14.91A11.046 11.046 0 0034 335.8c0 .51.41.93.92.94.51-.01.92-.43.92-.94-.03-5.07 4.06-9.21 9.13-9.25.51 0 .93-.42.93-.94zM68.16 325.52a7.972 7.972 0 00-3.79-14.91.935.935 0 000 1.87c3.17.13 5.72 2.67 5.85 5.85a6.089 6.089 0 01-5.85 6.34c-.03 0-.05.01-.08.02-.09 0-.17-.01-.26-.02a.939.939 0 100 1.88c5.07.04 9.15 4.18 9.12 9.25 0 .51.41.93.92.94.52 0 .93-.42.93-.94.02-4.63-2.81-8.61-6.84-10.28zM61.17 325.73l-.01-.01c-.44-.25-1.01-.1-1.26.35s-.1 1.02.34 1.28a11.44 11.44 0 015.62 9.86v.13c-.01.07-.01.13-.01.2v.01c-.02.51.38.93.89.95h.03c.5 0 .91-.41.92-.91 0-.04.01-.08.01-.12.01-.09.01-.17.01-.26 0-4.71-2.48-9.07-6.54-11.48z'
      />
    </g>
    <g>
      <path
        className='moive_solution_svg__st8'
        d='M956.43 317.54v-.13c-.07-4.79-4.01-8.61-8.8-8.54-4.79.07-8.61 4.01-8.54 8.8.05 3.25 1.88 6.04 4.54 7.49-5.06 1.86-8.65 6.72-8.62 12.42 0 .09 0 .18.01.27l.01.11c.01.5.42.91.92.91h.03c.51-.02.91-.46.89-.97 0-.06 0-.12-.01-.18v-.14c-.04-6.23 4.97-11.31 11.2-11.37.02 0 .05-.01.07-.01 4.64-.17 8.33-3.99 8.3-8.66zm-15.44-.1a6.801 6.801 0 016.9-6.7c3.73.03 6.73 3.07 6.7 6.8v.1c-.06 3.76-3.14 6.75-6.9 6.7s-6.75-3.14-6.7-6.9z'
      />
      <path
        className='moive_solution_svg__st8'
        d='M939.3 325.98c0-.52-.42-.94-.94-.94-.09 0-.18.01-.27.02-.03 0-.05-.02-.08-.02a6.106 6.106 0 01-5.85-5.85 6.089 6.089 0 015.85-6.34.935.935 0 000-1.87 7.972 7.972 0 00-3.79 14.91 11.046 11.046 0 00-6.82 10.28c0 .51.41.93.92.94.51-.01.92-.43.92-.94-.03-5.07 4.06-9.21 9.13-9.25.51 0 .93-.42.93-.94zM961.56 325.89a7.972 7.972 0 00-3.79-14.91.935.935 0 000 1.87c3.17.13 5.72 2.67 5.85 5.85a6.089 6.089 0 01-5.85 6.34c-.03 0-.05.01-.08.02-.09 0-.17-.01-.26-.02a.939.939 0 100 1.88c5.07.04 9.15 4.18 9.12 9.25 0 .51.41.93.92.94.52 0 .93-.42.93-.94.02-4.63-2.81-8.61-6.84-10.28zM954.57 326.1l-.01-.01c-.44-.25-1.01-.1-1.26.35s-.1 1.02.34 1.28a11.44 11.44 0 015.62 9.86v.13c-.01.07-.01.13-.01.2v.01c-.02.51.38.93.89.95h.03c.5 0 .91-.41.92-.91 0-.04.01-.08.01-.12.01-.09.01-.17.01-.26 0-4.71-2.48-9.07-6.54-11.48z'
      />
    </g>
    <g>
      <path
        className='moive_solution_svg__st8'
        d='M666.3 323.83h-11.43c-.26 0-.51-.1-.7-.29l-8.3-8.47c-1.15-1.14-3-1.14-4.15 0l-8.3 8.47a.97.97 0 01-1.3-.09c-.35-.37-.37-.93-.06-1.33l8.28-8.45a4.829 4.829 0 016.82-.1l.1.1 8.01 8.18h11.03c.55.08.93.58.85 1.13'
      />
      <path
        className='moive_solution_svg__st8'
        d='M664.45 303.25h-33.54c-2.18.02-3.93 1.82-3.91 4v23.98a3.967 3.967 0 003.91 4h15.66c.55 0 1-.45 1-1s-.45-1-1-1h-15.66c-.52 0-1.01-.21-1.38-.58a2.03 2.03 0 01-.57-1.42v-23.98c-.01-1.09.86-1.99 1.95-2h37.18c1.09.01 1.97.91 1.96 2v23.98c.01 1.09-.86 1.99-1.96 2-.55 0-1 .45-1 1s.45 1 1 1c2.18-.02 3.93-1.82 3.91-4v-23.98a3.967 3.967 0 00-3.91-4h-3.64z'
      />
      <path
        d='M659.93 327.74h-4.67c-.42 0-.81.21-1.11.58-.29.38-.46.89-.45 1.42v2.99h-1.08c-.67-.03-1.23.62-1.26 1.46-.01.52.17 1.01.48 1.35l4.6 5.61c.65.8 1.69.8 2.34 0l4.6-5.61c.28-.36.44-.84.45-1.35.03-.42-.1-.83-.35-1.12-.24-.29-.58-.41-.91-.34h-1.08v-2.99c0-.53-.16-1.04-.45-1.42-.31-.37-.7-.57-1.11-.58h0z'
        fillRule='evenodd'
        clipRule='evenodd'
        fill='none'
        stroke='#fff'
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        className='moive_solution_svg__st8'
        d='M662.73 310.79c-.54-.56-1.29-.88-2.07-.88a3 3 0 00-3.03 3.47c.18 1.21 1.15 2.22 2.35 2.46 1.89.38 3.57-1.03 3.61-2.87v-.07c0-.78-.31-1.55-.86-2.11zm-2.12 3.12c-.55.01-1.01-.43-1.02-.98v-.02c0-.26.1-.52.28-.71.19-.19.44-.29.7-.29.55-.01 1.01.43 1.02.98.01.55-.43 1.01-.98 1.02z'
      />
    </g>
  </svg>
)

export default SvgMoiveSolution
