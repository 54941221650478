import React from 'react'

const SvgMoiveFeature1 = props => (
  <svg id='moive_feature1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.moive_feature1_svg__st0{fill:#334966}.moive_feature1_svg__st1{fill:#fd5c1f}'}</style>
    <g id='moive_feature1_svg__\u6280\u672F\u4E13\u9898-48px'>
      <g id='moive_feature1_svg___x31_541402324594-48x48' transform='translate(1 5)'>
        <path
          id='moive_feature1_svg__Rectangle-23_1_'
          className='moive_feature1_svg__st0'
          d='M2 2v36h44V2H2zm46-2v40H0V0h48z'
        />
        <g id='moive_feature1_svg__Group_4_' transform='rotate(-45 16.657 .757)'>
          <path
            id='moive_feature1_svg__Combined-Shape_33_'
            className='moive_feature1_svg__st0'
            d='M8 9.41H0v-2h6v-6h2z'
          />
        </g>
        <path id='moive_feature1_svg__Rectangle-109' className='moive_feature1_svg__st1' d='M20 12h8v2h-8z' />
        <path id='moive_feature1_svg__Rectangle-108' className='moive_feature1_svg__st1' d='M8 24h38v10H8z' />
      </g>
    </g>
  </svg>
)

export default SvgMoiveFeature1
