import React from 'react'

const SvgMoiveFeature3 = props => (
  <svg id='moive_feature3_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.moive_feature3_svg__st0{fill:#fd5c1f}.moive_feature3_svg__st1{fill:#334966}'}</style>
    <path
      className='moive_feature3_svg__st0'
      d='M38.25 28.4c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6c-.1-5.3-4.3-9.6-9.6-9.6zm.5 12.3l-1.4 1.4-1.4-1.4-2.8-2.8 1.4-1.4 2.8 2.8 4.7-4.7 1.4 1.4-4.7 4.7z'
    />
    <path
      className='moive_feature3_svg__st1'
      d='M38.25 48.5c-5.8 0-10.6-4.7-10.6-10.6s4.7-10.6 10.6-10.6 10.6 4.7 10.6 10.6-4.8 10.6-10.6 10.6zm0-19.1c-4.7 0-8.6 3.8-8.6 8.6s3.8 8.6 8.6 8.6 8.6-3.8 8.6-8.6-3.9-8.6-8.6-8.6z'
    />
    <g id='moive_feature3_svg__Rectangle-2_3_' transform='translate(38 4)'>
      <path id='moive_feature3_svg__Rectangle-path_4_' className='moive_feature3_svg__st1' d='M-7.65.8h2v2h-2z' />
    </g>
    <g id='moive_feature3_svg__Rectangle-2-Copy-2_3_' transform='translate(34 4)'>
      <path id='moive_feature3_svg__Rectangle-path_3_' className='moive_feature3_svg__st1' d='M-7.65.8h2v2h-2z' />
    </g>
    <g id='moive_feature3_svg__Rectangle-2-Copy_3_' transform='translate(42 4)'>
      <path id='moive_feature3_svg__Rectangle-path_2_' className='moive_feature3_svg__st1' d='M-7.65.8h2v2h-2z' />
    </g>
    <g id='moive_feature3_svg__Rectangle-2_2_' transform='translate(38 4)'>
      <path id='moive_feature3_svg__Rectangle-path_16_' className='moive_feature3_svg__st1' d='M-3.65 11.7h2v2h-2z' />
    </g>
    <g id='moive_feature3_svg__Rectangle-2-Copy-2_2_' transform='translate(34 4)'>
      <path id='moive_feature3_svg__Rectangle-path_15_' className='moive_feature3_svg__st1' d='M-3.65 11.7h2v2h-2z' />
    </g>
    <g id='moive_feature3_svg__Rectangle-2-Copy_2_' transform='translate(42 4)'>
      <path id='moive_feature3_svg__Rectangle-path_1_' className='moive_feature3_svg__st1' d='M-3.65 11.7h2v2h-2z' />
    </g>
    <g id='moive_feature3_svg__Combined-Shape_28_'>
      <path
        className='moive_feature3_svg__st1'
        d='M6.15 34h-5V1.5h39.2v12.1h-2V3.5H3.15V32h3z'
        id='moive_feature3_svg__Shape_8_'
      />
    </g>
    <g id='moive_feature3_svg__Rectangle_4_' transform='translate(0 8)'>
      <path className='moive_feature3_svg__st1' d='M2.15.3h37.3v2H2.15z' id='moive_feature3_svg__Rectangle-path_17_' />
    </g>
    <g id='moive_feature3_svg__Combined-Shape_9_'>
      <path
        className='moive_feature3_svg__st1'
        d='M25.85 44.8H5.15V12.3h39.3v12.1h-2V14.3H7.15v28.5h18.7z'
        id='moive_feature3_svg__Shape_7_'
      />
    </g>
    <g id='moive_feature3_svg__Rectangle_3_' transform='translate(0 8)'>
      <path
        className='moive_feature3_svg__st1'
        d='M6.15 11.1h37.3v2H6.15z'
        id='moive_feature3_svg__Rectangle-path_21_'
      />
    </g>
    <g id='moive_feature3_svg__Rectangle-4_3_' transform='translate(7 17)'>
      <path
        className='moive_feature3_svg__st0'
        d='M3.35 9.7h10.2v2H3.35z'
        id='moive_feature3_svg__Rectangle-path_20_'
      />
    </g>
    <g id='moive_feature3_svg__Rectangle-4-Copy_3_' transform='translate(7 23)'>
      <path
        className='moive_feature3_svg__st0'
        d='M3.35 8.8h10.2v2H3.35z'
        id='moive_feature3_svg__Rectangle-path_19_'
      />
    </g>
    <g id='moive_feature3_svg__Rectangle-4-Copy-2_3_' transform='translate(7 29)'>
      <path
        className='moive_feature3_svg__st0'
        d='M3.35 7.8h10.2v2H3.35z'
        id='moive_feature3_svg__Rectangle-path_18_'
      />
    </g>
  </svg>
)

export default SvgMoiveFeature3
