import React from 'react'

const SvgMoiveAdvantage2 = props => (
  <svg id='moive_advantage2_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.moive_advantage2_svg__st2{fill:#334966}'}</style>
    <g id='moive_advantage2_svg__\u575A\u56FA\u7A33\u5B9A-48x48'>
      <circle
        id='moive_advantage2_svg__Oval-7_4_'
        cx={25}
        cy={25}
        r={23}
        fill='none'
        stroke='#334966'
        strokeWidth={2}
      />
      <path id='moive_advantage2_svg__Rectangle-54' fill='#fd5c1f' d='M25 28l12 6-12 6-12-6z' />
      <g id='moive_advantage2_svg__Group_5_' transform='translate(12 9)'>
        <path
          id='moive_advantage2_svg__Rectangle-54_1_'
          className='moive_advantage2_svg__st2'
          d='M13 13l12 6-12 6-12-6 12-6zm0 2.24L5.47 19 13 22.76 20.53 19 13 15.24z'
        />
        <path
          id='moive_advantage2_svg__Combined-Shape_21_'
          className='moive_advantage2_svg__st2'
          d='M12 22.26V6.11L3.92 18.22 12 22.26zm2-16.15v16.16l8.08-4.04L14 6.11zM1 19L13 1l12 18-12 6-12-6z'
        />
      </g>
    </g>
  </svg>
)

export default SvgMoiveAdvantage2
