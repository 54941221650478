import React from 'react'

const SvgMoiveAdvantage4 = props => (
  <svg id='moive_advantage4_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.moive_advantage4_svg__st0{fill:#334966}.moive_advantage4_svg__st1{fill:#fd5c1f}'}</style>
    <g id='moive_advantage4_svg__\u753B\u677F'>
      <g id='moive_advantage4_svg___x31_540812524083-48x48' transform='translate(3)'>
        <g id='moive_advantage4_svg__Fill-6_5_'>
          <path
            id='moive_advantage4_svg__\u5F62\u72B6'
            className='moive_advantage4_svg__st0'
            d='M20.92 1.13l-2.18 1.19A49.692 49.692 0 01-.08 8.13v21.53c0 5.21 2.89 9.99 7.5 12.4l13.5 7.07 13.5-7.07c4.61-2.42 7.5-7.19 7.5-12.4V8.13c-6.6-.66-13-2.64-18.82-5.81l-2.18-1.19zm0 2.28l1.22.67a51.488 51.488 0 0017.78 5.84v19.75c0 4.48-2.47 8.55-6.43 10.63l-12.57 6.58-12.57-6.59a11.96 11.96 0 01-6.43-10.63V9.91A51.07 51.07 0 0019.7 4.07l1.22-.66z'
          />
        </g>
        <g id='moive_advantage4_svg__Rectangle-74_2_' transform='translate(12 17)'>
          <path id='moive_advantage4_svg__\u77E9\u5F62' className='moive_advantage4_svg__st1' d='M-.08 1.13h6v16h-6z' />
        </g>
        <g id='moive_advantage4_svg__Rectangle-74-Copy_2_' transform='translate(24 13)'>
          <path
            id='moive_advantage4_svg__\u77E9\u5F62_1_'
            className='moive_advantage4_svg__st1'
            d='M-.08 1.13h6v20h-6z'
          />
        </g>
        <g id='moive_advantage4_svg__Path-5-Copy_2_' transform='translate(6 18)'>
          <path
            id='moive_advantage4_svg__\u8DEF\u5F84'
            className='moive_advantage4_svg__st0'
            d='M8.94 13.39L.32 6.93l1.2-1.6 7.39 5.54 12.33-9.74h7.68v2h-6.98z'
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgMoiveAdvantage4
